<template>
    <div class="invoiceCheck tableList" v-loading="loading">
        <div class="tableListBox">
            <div class="tableHeader">
                <div class="el_left">
                    <el-button @click="confirmSelection">
                        批量核对
                    </el-button>
                </div>
                <div class="el_right ">
                    <div class="el_left tableHeader_generalQuery">
                        <el-input placeholder="请输入搜索内容..." v-model="requestData.searchKey" class="input-with-select">
                            <el-button slot="append" icon="el-icon-search" @click="generalQuery"></el-button>
                        </el-input>
                    </div>
                    <div class="el_left tableHeader_setUp">
                        <el-button v-popover:popover>
                            <i class="iconfont iconshezhi"></i>
                        </el-button>
                        <el-popover
                            ref="popover"
                            placement="bottom-start"
                            title="自定义列表项"
                            width="540"
                            trigger="click"
                            v-model="isPopover"
                        >
                            <div class="tablePopover">
                                <div class="popoverContent">
                                    <el-checkbox-group v-model="selectListItem">
                                        <template v-for="(item, index) in listDisplayItems">
                                            <el-checkbox :key="index" :label="item.field">
                                                {{ item.title }}
                                            </el-checkbox>
                                        </template>
                                    </el-checkbox-group>
                                </div>
                                <div class="el_right">
                                    <el-button size="mini" @click="isPopover = false">
                                        取消
                                    </el-button>
                                    <el-button size="mini" type="primary" @click="confirmListItem">
                                        确定
                                    </el-button>
                                </div>
                            </div>
                        </el-popover>
                    </div>
                </div>
            </div>
            <div class="tableContent">
                <div class="table-bg infinite-list-wrapper">
                    <el-table
                        border
                        stripe
                        :data="tableList"
                        ref="invoiceCheckTable"
                        @row-click="selateTable"
                        :highlight-current-row="true"
                        @selection-change="handleSelectionChange"
                        show-summary
                        :summary-method="getSummaries"
                        class="table-list sum-Tble invoiceTable"
                        :row-style="getRowStyle"
                    >
                        <el-table-column
                            key="select"
                            type="selection"
                            width="55"
                            :selectable="selectable"
                        >
                        </el-table-column>
                        <template v-for="(item,index) in tableHeader">
                            <el-table-column
                                :key="index"
                                :prop="item.field"
                                :label="item.title"
                                :width="item.width"
                            >
                                <template slot-scope="scope">
                                    <!--表格内link列-->
                                    <div
                                        v-if="item.link"
                                        :style="item.style"
                                    >
                                        <el-link
                                            :type="item.link.linkType"
                                            @click.stop="handleLinkClick(item.link, scope.row)"
                                        >
                                            {{ scope.row[item.field] }}
                                        </el-link>
                                    </div>
                                    <div
                                        v-else-if="item.state"
                                        :style="item.style"
                                    >
                                        {{ item.state[scope.row[item.field]] || item.state.default }}
                                    </div>
                                    <div
                                        v-else
                                        :style="item.style"
                                        :class="{ 'hightcell':getHight(scope.row,item)}"
                                    >
                                        {{ scope.row[item.field] }}
                                    </div>
                                </template>
                            </el-table-column>
                        </template>
                    </el-table>
                </div>
                <div class="table-page">
                    <div class="el_right">
                        <el-pagination
                            layout="total, sizes, prev, pager, next, jumper"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="requestData.page+1"
                            :page-sizes="[20,40,60,80,100]"
                            :page-size="requestData.limit"
                            :total="total"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from './../../store';
import { eventBus } from '@/common/eventBus.ts';

export default {
    name: 'invoice-check',
    props: {
        extr: Object,
    },
    data() {
        return {
            tableData: {
                headerData: [{
                    field: 'delivery_receipt_number',
                    title: '发货单编号',
                    width: 200,
                    style: 'color:blue!important; cursor:pointer;',
                    link: {
                        linkType: 'primary',
                        method: this.showDeliveryForm,
                    },
                }, {
                    field: 'check_status_name',
                    title: '状态',
                    width: 150,
                }, {
                    field: 'delivery_receipt_status',
                    title: '办结状态',
                    width: 100,
                    state: {
                        3: '已办结',
                        default: '未办结',
                    },
                }, {
                    field: 'plate_number',
                    title: '车牌号',
                    width: 150,
                }, {
                    field: 'car_code',
                    title: '车号',
                    width: 150,
                }, {
                    field: 'task_current_trains',
                    title: '任务车次',
                }, {
                    field: 'deliver_time',
                    title: '发货时间',
                    width: 150,
                }, {
                    field: 'deliver_volume',
                    title: '发货方量',
                    width: 150,
                }, {
                    field: 'back_volume',
                    title: '退货方量',
                }, {
                    field: 'sign_time',
                    title: '签收时间',
                }, {
                    field: 'sign_volume',
                    title: '签收方量',
                }, {
                    field: 'adjusted_sign_volume',
                    title: '调整后签收方量',
                    width: 150,
                }, {
                    field: 'overage_volume',
                    title: '盈亏方量',
                    width: 120,
                }, {
                    field: 'adjusted_overage_volume',
                    title: '调整后盈亏方量',
                    width: 130,
                }, {
                    field: 'replenish_quantity',
                    title: '补方',
                    width: 160,
                }, {
                    field: 'adjusted_replenish_quantity',
                    title: '调整后补方',
                    width: 100,
                }, {
                    field: 'sjxm',
                    title: '司机姓名',
                }, {
                    field: 'sjsjh',
                    title: '司机手机号',
                    width: 120,
                }, {
                    field: 'pump_plate_number',
                    title: '调度泵车',
                    width: 120,
                }, {
                    field: 'site_pump_plate_number',
                    title: '工地泵车',
                    width: 120,
                }],
            },
            loading: false,
            showCheckBox: true,
            // 自定义列表项数据
            listDisplayItems: [],
            // 已选中显示项
            selectListItem: [],
            // 列表项选择弹出层状态
            isPopover: false,
            // 列表头部数据
            tableHeader: [],
            // 列表数据
            tableList: [],
            // 数据总条数
            total: 0,
            // 单选数据
            radio: {},
            // 列表选择数据集合
            tableSelectData: [],
            // 是否显示分页
            isShowTablePage: true,
            // 列表数据请求参数
            requestData: {
                cacheKey: '',
                code: '',
                limit: 100,
                page: 0,
                searchKey: '',
            },
            // 合计数据
            totalData: [],
        };
    },
    watch: {},
    computed: {},
    created() {
        this.listDisplayItems = this.tableData.headerData;
        this.selectListItem = this.tableData.headerData.map(item => item.field);
        this.tableHeader = this.tableData.headerData;

        this.requestData.cacheKey = Math.floor(Math.random() * 1000000000000 + 1);
        this.requestData.code = 'daily_delivery_check';
        this.getTableData();
        // 记录打开列表
        store.commit('recordOpenList', {
            tableObj: this,
            tableRefNmame: 'invoiceCheckTable',
        });
    },
    mounted() {
        // 添加事件总线eventBus/table刷新方法
        eventBus.$on('updateTable', () => {
            this.getTableData();
        });
    },
    methods: {
        // 刷新列表
        updateTable() {
            this.getTableData();
        },
        // 设置行样式。禁用时候灰色
        getRowStyle({ row }) {
            if (this.selectable(row)) {
                return { opacity: 1 };
            }
            return { opacity: 0.6 };
        },
        // 是否能选中
        selectable(row) {
            if (row.delivery_receipt_status === 3 && [1, 4].includes(row.approval_status)) {
                return true;
            }
            return false;
        },
        // 点击行选中
        selateTable(row) {
            if (this.selectable(row)) {
                this.tableSelectData = [];
                if (this.showCheckBox) {
                    this.$refs.invoiceCheckTable.toggleRowSelection(row);
                    this.tableSelectData = this.$refs.invoiceCheckTable.selection;
                } else if (this.showRadio) {
                    this.radio = row;
                    this.tableSelectData.push(row);
                }
            } else {
                this.$message.warning('当前发货单不能核对');
            }

        },
        // 多选选中数据集合
        handleSelectionChange() {
            this.tableSelectData = this.$refs.invoiceCheckTable.selection;
        },
        // 确认选择列表显示项
        confirmListItem() {
            const newColumns = [];
            this.listDisplayItems.forEach(k => {
                this.selectListItem.forEach(item => {
                    if (item === k.field) {
                        newColumns.push(k);
                    }
                });
            });
            this.tableHeader = newColumns;
            this.isPopover = false;
        },
        // 列表上link点击方法
        handleLinkClick(link, row) {
            link.method(row);
        },

        // 设置每页条数
        handleSizeChange(val) {
            this.requestData.limit = val;
            this.getTableData();
        },
        // 切换页码
        handleCurrentChange(val) {
            this.requestData.page = val - 1;
            this.getTableData();
        },
        // 查询
        generalQuery() {
            this.getTableData();
        },
        // 获取列表数据
        getTableData() {
            this.loading = true;
            this.request_Post();
        },
        // 获取合计数据
        async getTotalData() {
            await this.$axios
                .post('/interfaceApi/basicplatform/tabledata/total', this.requestData)
                .then(res => {
                    this.totalData = res;
                    this.loading = false;
                    this.$nextTick(() => {
                        this.$refs.invoiceCheckTable.doLayout();
                    });
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // post请求
        request_Post() {
            const requestUrl = '/interfaceApi/basicplatform/tabledata?task_number=' + this.extr.task_number
                + '&creation_date=' + this.extr.creation_date;
            this.$axios
                .post(requestUrl, this.requestData)
                .then(res => {
                    this.$set(this, 'tableList', res.rows);
                    // 数据总条数
                    this.total = res.total;
                    this.getTotalData();
                    // 强制刷新
                    this.$forceUpdate();
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        showDeliveryForm(row) {
            if (row.delivery_receipt_status === 3 && [1, 4].includes(row.approval_status)) {
                this.$HsAndHdLayer('FormCode=xs_accounts_ysd_hd', row.id, '核对完成', 'he', this);
            } else {
                this.$YWShowLayer('FormCode=xs_accounts_ysd_hd&IsView=true', row.id, this);
            }
        },
        confirmSelection() {
            const data = this.tableSelectData || [];
            // eslint-disable-next-line no-undefined
            if (data.length === 0) {
                this.$message('请选择发货单！');
                return;
            }
            const approval_status = data[0].approval_status;
            if (approval_status === 1 || approval_status === 4) {
                this.$confirm('是否完成核对选择的发货单?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    const daily_task_number = this.extr.daily_task_number;
                    const postData = { daily_task_number: daily_task_number, delivery_receipt_numbers: [] };
                    this.tableSelectData.forEach(element => {
                        postData.delivery_receipt_numbers.push(element.delivery_receipt_number);
                    });
                    this.$axios
                        .put('/interfaceApi/sale/accounts/batch_check_delivery', postData)
                        .then(res => {
                            if (res) {
                                this.$message.success('已完成核对！');
                                window.hackReset();
                                this.getTableData();
                            }
                        })
                        .catch(error => {
                            this.$message.error(error.ErrorCode.Message);
                            window.eventBus.$emit('updateTable');
                        });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消核对',
                    });
                });
            } else {
                this.$message({
                    type: 'warning',
                    message: '当前任务单已提交到核算，不能重新核对！',
                });
            }
        },
        // 合计行数据处理
        getSummaries() {
            const sums = new Array(this.tableHeader.length);
            const newColumns = [];
            this.tableHeader.forEach(item => {
                newColumns.push(item);
            });
            const newTotalData = Object.keys(this.totalData || []);
            newTotalData.forEach(item => {
                newColumns.forEach((k, i) => {
                    if (item === k.field) {
                        sums[i + 1] = this.totalData[item];
                    }
                });
            });
            sums[0] = '合计';
            return sums;
        },
        // 高亮
        getHight(row, item) {
            if (item.field === 'pump_plate_number' || item.field === 'site_pump_plate_number' ) {
                if (row.pump_plate_number !== row.site_pump_plate_number) {
                    return true;
                }
            }
            return false;
        },
    },
    beforeDestroy() {
        // 清除事件总线eventBus
        eventBus.$off('updateTable');
        // 删除记录打开列表
        store.commit('deleteRecordOpenList', 'invoiceCheckTable');
    },
};
</script>

<style lang="stylus">
.invoiceTable
    height 100%;
    td
        padding 0!important
    .cell
        width 100%;
        height 100%;
        padding 0;
        display flex
        justify-content center
        align-items center
        .hightcell
            width 100%;
            height 100%;
            display flex
            justify-content center
            align-items center
            background rgba(255,251,230,1)
            color rgba(250,93,2,1)
            border 1px solid rgba(255,251,130,1)
    .el-table__body-wrapper
        height calc(100% - 0.7rem)
</style>
